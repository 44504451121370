import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
    //loadChildren: () => import('./asb/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'asb',
    loadChildren: () => import('./asb/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'dueslag',
    loadChildren: () => import('./pages/dueslag/dueslag.module').then( m => m.DueslagPageModule)
  },
  {
    path: 'dueslag/:id',
    loadChildren: () => import('./pages/folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'laaneoversigt',
    loadChildren: () => import('./pages/laaneoversigt/laaneoversigt.module').then( m => m.LaaneoversigtPageModule)
  },
  {
    path: 'stamdata',
    loadChildren: () => import('./pages/stamdata/stamdata.module').then( m => m.StamdataPageModule)
  },
  {
    path: 'vejeboner',
    loadChildren: () => import('./pages/vejeboner/vejeboner.module').then( m => m.VejebonerPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'notification-detail',
    loadChildren: () => import('./pages/notification-detail/notification-detail.module').then( m => m.NotificationDetailPageModule)
  },
  {
    path: 'folder',
    loadChildren: () => import('./pages/folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'forms-available',
    loadChildren: () => import('./pages/forms-available/forms-available.module').then( m => m.FormsAvailablePageModule)
  },
  {
    path: 'forms',
    loadChildren: () => import('./pages/forms/forms.module').then( m => m.FormsPageModule)
  },
  {
    path: 'form-helper',
    loadChildren: () => import('./pages/forms/rentalagreement/helper-select-farmer/helper-select-farmer.module').then( m => m.HelperSelectFarmerPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'force-update',
    loadChildren: () => import('./pages/force-update/force-update.module').then( m => m.ForceUpdatePageModule)
  },
  {
    path: 'useradmin',
    loadChildren: () => import('./pages/useradmin/useradmin.module').then( m => m.UseradminPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}