import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { ConfigService } from '../config/config.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

interface deviceInterface {
  id?: string,
  model?: string,
  cordova?: string,
  platform?: string,
  version?: string,
  manufacturer?: string,
  serial?: string,
  isVirtual?: boolean,
};

@Injectable({
  providedIn: 'root'
})
export class DeviceService {


  private isSubmitting: Boolean = false;

  constructor(private config: ConfigService, private platform: Platform, private router: Router, private http: HttpClient) { }

  ngOnInit() {
    this.platform.ready().then(() => {
      Device.getId().then(res => {
        console.log('LOG2')
        this.config.deviceinfo.id = res.identifier;
        this.config.deviceinfo.serial = res.identifier;
        Device.getInfo().then(res => {
          console.log('LOG3')
          this.config.deviceinfo.manufacturer = res.manufacturer;
          this.config.deviceinfo.model = res.model;
          this.config.deviceinfo.platform = res.platform;
          this.config.deviceinfo.version = res.operatingSystem;
          this.config.deviceinfo.version = res.osVersion;
          console.log(this.config.deviceinfo);

          //this.initPushNotification();
          if (res.platform !== 'web') {
            this.initPushNotification();
          }
        });
      });
    });
  }

  async initPushNotification() {
    console.log('LOG4');

    

    if (!this.platform.is('cordova')) {
      console.log('Push notifications not initialized. Cordova is not available - Run in physical device');
      return;
    }

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    //await PushNotifications.register();



    await PushNotifications.addListener('registration', token => {
      
      console.log('ASB Registration token: ', token.value);
      //this.auth.registerDeviceId(token.value);
      console.log('REGISTERRR', localStorage.getItem('profile'))
      localStorage.setItem('device_token', token.value);
      this.registerDeviceId(token.value, parseInt(JSON.parse(localStorage.getItem('profile')).id))
    });
  
    await PushNotifications.addListener('registrationError', err => {
      console.log('ASB Registration error: ', err.error);
    });
  
    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('ASB Push notification received: ', notification);
    });
  
    /*await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      console.log('ASB Push notification action performed', notification.actionId, notification.inputValue);
    });*/

    await PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
      
    
      const data = notification.notification.data; // Access the data payload
      console.log('Push notification action performed:', JSON.stringify(notification, null, 4), data.category, data.id);


      if (data.category === 'weighins' && data.id) {
        // Navigate to the specific route with the ID
        this.router.navigate([`/vejeboner/vejebon/${data.id}`], { queryParams: { serialnumber: data.serialnumber } });
        //this.router.navigate([`/vejeboner`], { queryParams: { id: data.id, serialnumber: data.serialnumber } });
      } else if(data.category === 'rentalagreement'){
        this.router.navigate([`/notifications`]);
      } else if(data.category === 'skimmelvarsling'){
        this.router.navigate([`/asb/skimmelvarsling`]);
      } else if(data.category === 'sortsinfo'){
        this.router.navigate([`/asb/sortsinfo`]);
      } else if(data.category === 'dueslag'){
        this.router.navigate([`/dueslag`]);
      } else if(data.category === 'forms'){
        this.router.navigate([`/forms`]);
      }
    });

    console.log('LOG5');
    await PushNotifications.register();   
    console.log('LOG6');



  }


  registerDeviceId(token:string, userId: number){
console.log('REGISTERDEVICEID', token, userId);
    if(!this.isSubmitting){
      this.isSubmitting = true;


      
    
    
    
    //if(userId && token.length>0){
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Authorization', localStorage.getItem('access_token') || '{}');

      const options = {
        headers: headers
      };


      
      //let deviceinfo: deviceInterface = {};

      Device.getId().then(res => {
        console.log('LOG2')
        this.config.deviceinfo.id = res.identifier;
        this.config.deviceinfo.serial = res.identifier;
        Device.getInfo().then(res => {
          console.log('LOG3')
          this.config.deviceinfo.manufacturer = res.manufacturer;
          this.config.deviceinfo.model = res.model;
          this.config.deviceinfo.platform = res.platform;
          this.config.deviceinfo.version = res.operatingSystem;
          this.config.deviceinfo.version = res.osVersion;



      let deviceReg = {'devicetoken':token, 'farmerId':userId, 'deviceinfo': JSON.stringify(this.config.deviceinfo)};

      console.log('deviceReg', deviceReg);
      this.http.post(this.config.basePath + '/api/farmers/registerDevice', deviceReg, options).subscribe(
          (res) => {
            if (res) {
              //if (res.status === 200) {
                console.log('Token_success', res['devicetoken']);
                localStorage.setItem("device_token", res['devicetoken']);
                this.isSubmitting = false;
             // }
            }//
          },
          (err) => {
            console.log('Token_error', err.message);
            this.isSubmitting = false;
          }
        );
        });
      });
        
      
      
    //}else{
      //localStorage.setItem("device_token", token);
    //}
  }
}

}
