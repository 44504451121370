import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormattingService {

  constructor(private datePipe: DatePipe) { }

  getFormattedSerialnumber(serialnumber: string): string {
    return `Løbenummer: ${serialnumber}`;
  }

  formatNumber(value: any, decimals?: boolean): string {
    
    value = Number(value);
    value = Math.round(value);
    
    let [whole, fraction] = value.toFixed(2).split(".");
    whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    if (!decimals){
      return `${whole}`;
    }
    return `${whole},${fraction}`;
  }

  formatPercentage(value: any, decimals?: boolean): string {
    value = Number(value);
    let [whole, fraction] = value.toFixed(1).split(".");
    whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    if (!decimals){
      return `${whole}`;
    }
    return `${whole},${fraction}`;
  }

  getFormattedDate(dateStr: string): string {
    let date = this.datePipe.transform(dateStr, 'dd-MM-yy', 'UTC');
    let time = this.datePipe.transform(dateStr, 'HH.mm', 'UTC');
    return `${date} Kl. ${time}`;
  }

  formatDate(dateStr: string): string {
    let date = this.datePipe.transform(dateStr, 'dd.MM.yy');
    return `${date}`;
  }

  formatToFromData(value: any): string {
    let startDate = this.formatDate(value.start_date);
    let expiryDate = this.formatDate(value.expiry_date);
    return `${startDate} - ${expiryDate}`;
  }
}
