import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthService } from '../../../service/auth/auth.service';
import { ConfigService } from '../../../service/config/config.service';

import { IonSlides } from '@ionic/angular';
import { HelperSelectFarmerPage } from './helper-select-farmer/helper-select-farmer.page';
import { LocaldataService } from '../../../service/localdata/localdata.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';




@Component({
  selector: 'app-rentalagreement',
  templateUrl: './rentalagreement.page.html',
  styleUrls: ['./rentalagreement.page.scss'],
})
export class RentalagreementPage implements OnInit {

  @ViewChild('slides') slides: IonSlides;
  //@ViewChild('slides', {static: true}) slides: IonicSlides;

  private appversion;
  private appplatform;
  private deviceinfo;

  private form: any;
  private factory: Factory;
  private year: string;

  public datesVisible: boolean = false;
  public senderSelected: boolean;
  public factorySelected: boolean;
  

  public selectedFarmer = {displayname: "Vælg avler", farmernumber:'', address: {street: ''}, id:0};


  public amount: number;

  public displayName: string;

  public senderType: string;

  public senderFactory: string;

  public periodText: string = '';


  private periodBegin: string;
  private periodEnd: string;

  private periodBeginArray:any = [];
  private periodEndArray:any = [];

  private canBeClosed: boolean = false;

  public description: string;

  public formSubmitting: boolean = false;

  @Input() modalCtrl: ModalController;
  @Input() formobject: any;

  constructor(private route: ActivatedRoute, public config: ConfigService, private auth: AuthService, private localData: LocaldataService ,private alertCtrl: AlertController, private http: HttpClient) {
    this.appversion = this.config.version;
    this.appplatform = this.config.platformName;
    this.deviceinfo = this.config.deviceinfo;
    
    var currentYear = new Date().getFullYear();
    for(let i:number = 0; i<2; i++){
      this.periodBeginArray.push(`${currentYear+i}-${currentYear+i+1}`)
    }
    for(let i:number = 0; i<5; i++){
      this.periodEndArray.push(`${currentYear+i}-${currentYear+i+1}`)
    }
  }

  getDescription():string{
    let factory:string = `<br/>Lejaftalen gælder <strong>${this.senderFactory}-andele</strong>`;
    return 'Jeg, <strong>' + this.auth.userProfile.displayname + ' (' + this.auth.userProfile.farmernumberakd.substr(2)  + ')</strong> ' + (this.senderType=='tenant' ? 'lejer': 'udlejer') + ' hermed leverancen af <strong>' + this.amount + ' andele</strong> ' + (this.senderType=='tenant' ? 'fra': 'til') + ' <strong>' + this.selectedFarmer.displayname + ' (' + this.selectedFarmer.farmernumber +  ')</strong> ' + (!this.datesVisible ? ' for kampagneperioden fra og med <strong>' + this.periodBegin + '</strong> til og med <strong>' + this.periodEnd + '</strong>.' : '') + factory;
  }

  async openSelectFarmer(){
    //let farmerModal = awaitthis.modalCtrl.create(FormsHelperSelectFarmerPage, { factory: this.factory });

    let farmerModal = await this.modalCtrl.create(
      {
        component: HelperSelectFarmerPage,
        componentProps: {
          factory:  this.factory,
          modalCtrl: this.modalCtrl
        },
      });

      farmerModal.onDidDismiss().then((data) => {
        if(data){
          this.selectedFarmer = data.data;
        }
       
      });


      farmerModal.present();
  }






  changePeriod(paramTarget){
    if(paramTarget.name=='periodBegin'){
      this.periodBegin = paramTarget.value;
    }else if(paramTarget.name=='periodEnd'){
      this.periodEnd = paramTarget.value;
    }
  }

  changeState(paramState:string){
    if(paramState == 'yes'){
      this.datesVisible = true;
    }else{
      this.datesVisible = false;
    }
  }

  changeSenderState(paramState:string){
    if(paramState == 'tenant'){
      this.senderSelected = true
    }else{
      this.senderSelected = false;
    }
    this.senderType = paramState;
  }

  changeFactoryState(paramState:string){
    if(paramState == 'tenant'){
      this.factorySelected = true
    }else{
      this.factorySelected = false;
    }
    this.senderFactory = paramState;
  }

  async nextSlide(){
    let slideNumber = await this.slides.getActiveIndex();

    if(slideNumber===2){
      if(!this.periodBegin){
        let alert = await this.alertCtrl.create({
          header: 'Bemærk',
          message: 'Du mangler at udfylde starttidspunktet for aftalen',
          buttons: ['OK'],
        });
        await alert.present();
        return;
      }
      if(!this.periodEnd){
        let alert = await this.alertCtrl.create({
          header: 'Bemærk',
          message: 'Du mangler at udfylde sluttidspunktet for aftalen',
          buttons: ['OK']
        });
        await alert.present();
        return;
      }
      if(!this.amount || this.amount<=0){
        let alert = await this.alertCtrl.create({
          header: 'Bemærk',
          message: 'Du mangler at udfylde antal andele aftalen skal gælde for',
          buttons: ['OK'],
        });
        await alert.present();
        return;
      }
    }


    if(slideNumber===3){
      if(this.selectedFarmer.farmernumber.length<=0){
        let alert = await this.alertCtrl.create({
          header: 'Bemærk',
          message: 'Du mangler at udfylde den avler aftalen skal indgås med med',
          buttons: ['OK'],
        });
        await alert.present();
        return;
      }
    }

    this.slides.slideNext();

    /*if(this.slides._slides[this.slides.getActiveIndex()].getAttribute("validated")=="true"){
      this.slides.slideNext();
    }*/
  }

  async submitForm(orderForm:NgForm) {
    if(this.formSubmitting){return}

    this.formSubmitting = true;


    let dealtype: string = (this.periodEnd==='aftalen opsiges' ? 'indefinitely' : 'restricted');

    var submitformdata =
      {
        "dealtype": dealtype,

        "senderIsRenter": this.senderSelected,
        "amount": this.amount,
        "beginDate": this.periodBegin,
        "endDate": this.periodEnd,
        "createdBy": this.auth.userProfile.id,



        "senderId": this.auth.userProfile.id,
        "receiverId": this.selectedFarmer.id,
        "factoryId": this.factory.id,


        "description": this.getDescription(),

        "senderDisplayname": this.auth.userProfile.displayname + ' (' + this.auth.userProfile.farmernumberaks + ')',
        "receiverDisplayname": this.selectedFarmer.displayname + ' (' + this.selectedFarmer.farmernumber + ')',


        'status': 'approvedBySender',


        'appversion': this.appversion,
        'appplatform': this.appplatform,
        'deviceinfo': this.deviceinfo

      };



      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Authorization', localStorage.getItem('access_token') || '{}');

      const options = {
        headers: headers
      };
      


    this.http.post(this.config.basePath + '/api/rentalagreements', submitformdata, options)
      .subscribe(
        (res) => {

          this.formSubmitting = false;

          if (res) {
            //if (res.status === 200) {

              if (this.form.onceperyear) {
                this.form.statuscode = 'completed';
              }

              this.localData.remoteUpdateFormsAvailable().then(() => {
                this.nextSlide();
              });

            //}
          }
        },
        (err) => {
          this.submitError();
          
        }
      );
  }

  async submitError(){
    let alert = await this.alertCtrl.create({
      header: 'Der opstod en fejl ved indsendelse',
      message: 'Prøv igen. Hvis problemet fortsætter kontakt da venligst ' + this.factory.displayname,
      buttons: ['OK'],
    });
    await alert.present();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {

    this.form = this.formobject;
    console.log('log', this.formobject)
    this.factory = this.formobject.factory;
    this.year = this.formobject.year;
    this.localData.remoteGetFarmers(105).then(()=>{})

  }

}

export interface Factory {
  id: any;
  displayname: any;
  address: any;
}

export interface Form {
  component: any;
  createdAt: any;
  createdBy: any;
  deadline: any;
  deletedAt: any;
  deletedBy: any;
  displayDeadline: any;
  displayIcon: any;
  displayname: any;
  factory: any;
  factoryId: any;
  formproperties: any;
  forms: any;
  id: any;
  isSoldOut: any;
  isSpecial: any;
  msgAlreadySubmitted: any;
  msgDateOutOfRange: any;
  onceperyear: any;
  orderYear: any;
  publisher: any;
  soldOutMsg: any;
  status: any;
  systemname: any;
  tandc: any;
  updatedAt: any;
  updatedBy: any;
  year: any;
}