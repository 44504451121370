import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocaldataService } from './service/localdata/localdata.service';
import { AuthService } from './service/auth/auth.service';
import { ConfigService } from './service/config/config.service';
import { DeviceService } from './service/device/device.service';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';


import { NgPipesModule } from 'ngx-pipes';
import { PipesModule } from './pipes/pipe.module';

import { NgChartsModule } from 'ng2-charts';
import { RentalagreementPageModule } from './pages/forms/rentalagreement/rentalagreement.module';
import { TermsandconditionsComponent } from './components/termsandconditions/termsandconditions.component'
import { FormattingService } from './service/formatting/formatting.service';
import { ReactiveFormsModule } from '@angular/forms';





@NgModule({
  declarations: [AppComponent, RentalagreementPageModule, TermsandconditionsComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, NgPipesModule, NgChartsModule, ReactiveFormsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, LocaldataService, AuthService, ConfigService, InAppBrowser, DeviceService, DatePipe, FormattingService],
  bootstrap: [AppComponent],
})
export class AppModule {}