import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-force-update',
  templateUrl: './force-update.page.html',
  styleUrls: ['./force-update.page.scss'],
})
export class ForceUpdatePage implements OnInit {

  @Input() modalCtrl: ModalController;

  constructor(public platform: Platform) { }

  ngOnInit() {
  }

  closeModal() {
    return this.modalCtrl.dismiss();
  }

  gotoNewVersionDownload(){
    const downloadLink: string = (this.platform.is('android') ? 'https://play.google.com/store/apps/details?id=dk.avlerinfo.selvbetjening' : 'https://itunes.apple.com/us/app/avlerinfo-selvbetjening/id1387807995?mt=8');
    window.open(downloadLink);
  }

}
