import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { DeviceService } from '../device/device.service';






@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userProfile: any = JSON.parse('{"id":0}');
  public isLoggedin: boolean = false;
  public isLoggedin$ = new BehaviorSubject<boolean>(this.isLoggedin);

  public tAndCsVisible: boolean = false;

  private nav:NavController;


  constructor(public DeviceService: DeviceService, private http:HttpClient, private router: Router, private config: ConfigService, private alertCtrl: AlertController) { 
    //this.nav = this.app.;

    const lsProfile = JSON.parse(localStorage.getItem('profile'));

    if(this.tokenValid) {
      this.userProfile = lsProfile;
      this.setLoggedIn(true);
      
      this.DeviceService.initPushNotification();
      //this.router.navigate(['/asb/home']);
      //this.router.navigate['/asb/home']

    } else if (!this.tokenValid) {
      this.logout();
    }

    this.isLoggedin$.subscribe((subscribed)=>{
      //console.log('subscribed', subscribed);
    })
   }
   setLoggedIn(value: boolean) {
    this.isLoggedin$.next(value);
    this.isLoggedin = value;
  }
  logout() {
    // Ensure all auth items removed from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('authRedirect');

    // Reset local properties, update loggedIn$ stream
    this.userProfile = JSON.parse('{"id":0}');
    this.setLoggedIn(false);

    this.router.navigateByUrl('');
  }
   get tokenValid(): boolean {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return Date.now() < expiresAt;
  }

  get currentToken(): string | null {
    return localStorage.getItem('access_token');
  }


  async ngOnInit() {
    //const value = await this.readFromLocalStorage('myKey');
    // Value is guaranteed to be read
  }

  readFromLocalStorage(key: string): Promise<string | null> {
    return new Promise(resolve => {
       const value = localStorage.getItem(key);
       resolve(value);
    });
  }






  
}

