import { Component } from '@angular/core';
import { BackgroundColorOptions, StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { LocaldataService } from './service/localdata/localdata.service';
import { AuthService } from './service/auth/auth.service';
import { Platform } from '@ionic/angular';
import { ConfigService } from './service/config/config.service';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { DeviceService } from './service/device/device.service';

import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public mainnav: boolean = false;


  constructor(private config: ConfigService, private DeviceService: DeviceService , private router: Router, private localData: LocaldataService, private auth: AuthService, private platform: Platform) {

    let version: string = localStorage.getItem('version_accepted');
    let currentVersion: string = this.config.version;
    if(version==null || parseInt(currentVersion)<parseInt(version)){
      this.auth.tAndCsVisible = true;
    }

    console.log('initialize', version, currentVersion, this.auth.tAndCsVisible);

  }



  ngOnInit() {
    //console.log('APP init', this.auth.isLoggedin, this.platform);



    this.platform.ready().then(() => {
      /*console.log('READY');



      if (this.platform.is('desktop')) {
        this.config.platformName = 'desktop';
      }
      if (this.platform.is('mobileweb')) {
        this.config.platformName = 'mobileweb';
      }
      if (this.platform.is('cordova')) {
        this.config.platformName = 'cordova';
      }
      if (this.platform.is('mobile')) {
        this.config.platformName = 'mobile';
      }
      if (this.platform.is('android')) {
        this.config.platformName = 'android';
      }
      if (this.platform.is('tablet')) {
        this.config.platformName = 'tablet';
      }
      if (this.platform.is('ios')) {
        this.config.platformName = 'ios';
      }
      if (this.platform.is('ipad')) {
        this.config.platformName = 'ipad';
      }
      if (this.platform.is('iphone')) {
        this.config.platformName = 'iphone';
      }*/

      //if(this.config.platformName=='android'){

        if (Capacitor.isPluginAvailable('StatusBar')) {
          StatusBar.setOverlaysWebView({ overlay: false });
          //StatusBar.hide();
          StatusBar.setBackgroundColor({color:'#59A8C7'});
        }

        //let bgColor: BackgroundColorOptions = {color: '#59A8C7'};
        //StatusBar.setBackgroundColor(bgColor);
      //}

      //console.log('LOG1')
      Device.getId().then(res => {
       /* console.log('LOG2')
        this.config.deviceinfo.id = res.identifier;
        this.config.deviceinfo.serial = res.identifier;*/
        Device.getInfo().then(res => {
          /*console.log('LOG3')
          this.config.deviceinfo.manufacturer = res.manufacturer;
          this.config.deviceinfo.model = res.model;
          this.config.deviceinfo.platform = res.platform;
          this.config.deviceinfo.version = res.operatingSystem;
          this.config.deviceinfo.version = res.osVersion;
          console.log(this.config.deviceinfo);*/

          //this.initPushNotification();
          if (res.platform !== 'web') {
            this.DeviceService.initPushNotification();
            //this.initPushNotification();
          }
        });
      });
      



      /*this.config.deviceinfo.id = this.device.uuid;
      this.config.deviceinfo.model = this.device.model;
      this.config.deviceinfo.cordova = this.device.cordova;
      this.config.deviceinfo.platform = this.device.platform;
      this.config.deviceinfo.version = this.device.version;
      this.config.deviceinfo.manufacturer = this.device.manufacturer;
      this.config.deviceinfo.serial = this.device.serial;
      this.config.deviceinfo.isVirtual = this.device.isVirtual;*/


      //console.log('plats', this.config.platformName);


    });
  }


  //console.log('initPushNotification', this.platform.is('cordova'));





  
  async initPushNotification() {
    console.log('LOG4');

    if (!this.platform.is('cordova')) {
      console.log('Push notifications not initialized. Cordova is not available - Run in physical device');
      return;
    }

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    //await PushNotifications.register();



    await PushNotifications.addListener('registration', token => {
      console.log('ASB Registration token: ', token.value);
      //this.auth.registerDeviceId(token.value);
    });
  
    await PushNotifications.addListener('registrationError', err => {
      console.log('ASB Registration error: ', err.error);
    });
  
    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('ASB Push notification received: ', notification);
    });
  
    /*await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      console.log('ASB Push notification action performed', notification.actionId, notification.inputValue);
    });*/

    await PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
      console.log('Push notification action performed:', notification);
    
      const data = notification.notification.data; // Access the data payload
      
      if (data.category === 'weighins' && data.id) {
        // Navigate to the specific route with the ID
        this.router.navigate([`/vejeboner/vejebon/${data.id}`]);
      } else if(data.category === 'rentalagreement'){
        this.router.navigate([`/notifications`]);
      } else if(data.category === 'skimmelvarsling'){
        this.router.navigate([`/asb/skimmelvarsling`]);
      } else if(data.category === 'sortsinfo'){
        this.router.navigate([`/asb/sortsinfo`]);
      } else if(data.category === 'dueslag'){
        this.router.navigate([`/dueslag`]);
      } else if(data.category === 'forms'){
        this.router.navigate([`/forms`]);
      }
    });

    console.log('LOG5');
    await PushNotifications.register();   
    console.log('LOG6');





    if(!this.auth.isLoggedin){
      this.navigateTo('/login');
      console.log('NAVIGATE TO LOGIN');
      return;
    }else{
      this.localData.initData();
      this.navigateTo('/asb/home');
      console.log('NAVIGATE TO HOME');
    }

  }


  navigateTo(path: string):void{
    this.router.navigateByUrl(path);
    this.mainnav = false;
  }

}







