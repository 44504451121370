import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocaldataService } from '../../../../service/localdata/localdata.service';

@Component({
  selector: 'app-helper-select-farmer',
  templateUrl: './helper-select-farmer.page.html',
  styleUrls: ['./helper-select-farmer.page.scss'],
})
export class HelperSelectFarmerPage implements OnInit {

  searchQuery: string = '';
  items: any[];

  private factory = {id:105};

  @Input() modalCtrl: ModalController;

  constructor(private localData: LocaldataService) { }

  initializeItems() {
    this.items = [];

    for(var i:number = 0; i<this.localData.farmers.length; i++){
      if(this.factory.id==100 && this.localData.farmers[i].akm && this.localData.farmers[i].farmernumberakm){
        
        this.items.push({
          displayname: this.localData.farmers[i].displayname,
          farmernumber: this.localData.farmers[i].farmernumberakm.substr(2),
          address: this.localData.farmers[i].address,
          id: this.localData.farmers[i].id
        });
      }else if(this.factory.id==101 && this.localData.farmers[i].aks && this.localData.farmers[i].farmernumberaks){
        this.items.push({
          displayname: this.localData.farmers[i].displayname,
          farmernumber: this.localData.farmers[i].farmernumberaks.substr(2),
          address: this.localData.farmers[i].address,
          id: this.localData.farmers[i].id
        });
      }else if(this.factory.id==103 && this.localData.farmers[i].granules && this.localData.farmers[i].farmernumbergranules){
        this.items.push({
          displayname: this.localData.farmers[i].displayname,
          farmernumber: this.localData.farmers[i].farmernumbergranules.substr(2),
          address: this.localData.farmers[i].address,
          id: this.localData.farmers[i].id
        });
      }else if(this.factory.id==105 && this.localData.farmers[i].akd && this.localData.farmers[i].farmernumberakd){  
        this.items.push({
          displayname: this.localData.farmers[i].displayname,
          farmernumber: this.localData.farmers[i].farmernumberakd.substr(2),
          address: this.localData.farmers[i].address,
          id: this.localData.farmers[i].id
        });
      }else if(this.factory.id==110){
        this.items.push({
          displayname: this.localData.farmers[i].displayname,
          farmernumber: this.localData.farmers[i].farmernumberaks.substr(2),
          address: this.localData.farmers[i].address,
          id: this.localData.farmers[i].id
        });
      }
    }

  }

  getItems(ev: any) {console.log('ev',ev.target.value)
    // Reset items back to all of the items
    this.initializeItems();

    // set val to the value of the searchbar
    let val = ev.target.value;

    // if the value is an empty string don't filter the items
    if (val && val.trim() != '') {
      this.items = this.items.filter((item) => {
        return ((item.displayname && item.displayname.toLowerCase().indexOf(val.toLowerCase()) > -1) || (item.farmernumber && item.farmernumber.toLowerCase().indexOf(val.toLowerCase()) > -1) || (item.address.street && item.address.street.toLowerCase().indexOf(val.toLowerCase()) > -1));
      })
    }
  }

  selectFarmer(paramSelectedFarmer: String){
    //this.view.dismiss(paramSelectedFarmer);
    this.modalCtrl.dismiss(paramSelectedFarmer);
    console.log(paramSelectedFarmer)
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }


  ngOnInit() {
    this.localData.remoteGetFarmers(105).then(()=>{})
  }

}
