import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../service/auth/auth.service';
import { ConfigService } from '../../service/config/config.service';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.scss'],
})
export class TermsandconditionsComponent implements OnInit {

  constructor(private config: ConfigService, private auth: AuthService) { }

  ngOnInit() {}

  submitTandCs(form:NgForm){
    localStorage.setItem('version_accepted', this.config.version);
    this.auth.tAndCsVisible = false;
  }

}
