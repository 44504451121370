import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RentalagreementPage } from './rentalagreement.page';

const routes: Routes = [
  {
    path: '',
    component: RentalagreementPage
  },
  {
    path: 'helper-select-farmer',
    loadChildren: () => import('./helper-select-farmer/helper-select-farmer.module').then( m => m.HelperSelectFarmerPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RentalagreementPageRoutingModule {}
